<script>
import appConfig from "@/app.config";
import PDFViewer from "../../../../layouts/pdf-viewer.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { DocumentEditor } from "@onlyoffice/document-editor-vue";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Pengerjaan Tes Tipe 9",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PDFViewer,
    VueCountdown,
    DocumentEditor,
  },
  data() {
    return {
      id_jadwal: null,
      id_jadwal_detail: null,
      tipe: null,
      tipe_pengerjaan: null,

      url_backend_pdf_js: process.env.VUE_APP_BACKEND_URL + "/pdf.js/web/viewer.html?file=",

      is_petunjuk_soal_rich_text: false,
      content_petunjuk_soal_rich_text: "Loading...",
      editorData: "<h3>Masukkan Jawaban Anda Disini.</h3>",

      file_embed: "",
      accordionPentunjuk: false,
      accordionSoal: false,
      url_petunjuk: "",
      url_soal: "",
      isFullScreenJawaban: false,
      isFullScreenSoal: false,
      txt_materi: "",
      txt_simulasi: "",

      jadwal_data: null,

      countDownSoal: 10,
      is_soal_enable: true,
      is_soal_active: null,
      last_update_soal: "-",
      list_soal: [],
      list_petunjuk: [],

      waktu_mulai: 0,
      sisa_waktu: 3600000,
      url_onlyoffice: process.env.VUE_APP_ONLYOFFICE_URL,
      config: {
        type: "desktop",
        width: "100%",
        height: "100%",
        documentType: "slide",
        document: {
          key: `${new Date().toISOString()}-${this.$route.params.id_jadwal}-${this.$route.params.id_jadwal_detail}`,
          title: `presentasi_${this.$route.params.id_jadwal}_${this.$route.params.id_jadwal_detail}.pptx`,
          url: `${process.env.VUE_APP_BACKEND_URL_VERSION}callback-onlyoffice/${this.$route.params.id_jadwal}/${this.$route.params.id_jadwal_detail}`,
          fileType: "pptx",
          permissions: {
            edit: true,
            download: false,
            print: false,
            copy: false,
          },
        },
        editorConfig: {
          mode: "view",
          callbackUrl: `${process.env.VUE_APP_BACKEND_URL_VERSION}callback-onlyoffice/${this.$route.params.id_jadwal}/${this.$route.params.id_jadwal_detail}`,
          customization: {
            autosave: true,
            chat: false,
            comments: false,
            forcesave: true,
            hideRightMenu: true,
            plugins: false,
            zoom: -1,
            anonymous: {
              request: false,
            },
          },
        },
      },
    };
  },
  mounted() {
    let self = this;
    self.id_jadwal = self.$route.params.id_jadwal;
    self.id_jadwal_detail = self.$route.params.id_jadwal_detail;
    self.tipe = self.$route.params.tipe;
    self.tipe_pengerjaan = self.$route.params.tipe_pengerjaan;
    self.getJadwalData();
  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    getJadwalData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/jawaban-jadwal-data";
      var config = {
        method: "get",
        url: url,
        params: {
          id_jadwal_detail: self.id_jadwal_detail,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.jadwal_data = response_data.data;
            this.getDataDurasi();
            this.getInfoSimulasi();
            this.fetchPetunjuk();
            this.fetchSoal();
            Swal.close();
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getDataDurasi() {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/get-durasi-data";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal_batch: self.jadwal_data.jadwal_batch.id_jadwal_batch,
          id_jadwal_detail: self.jadwal_data.jadwal_detail.id,
          tipe: self.tipe,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.waktu_mulai = response_data.data;
            var is_combo = self.jadwal_data.jadwal_detail.jadwal_new.j_is_combo;
            console.log(is_combo);
            if (response_data.data == 0) {
              Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "Waktu Pengerjaan Selesai, Menuju ke halaman Kuesioner.",
              });
              /*
              if (is_combo == "NO") {
                this.$router.push({
                  name: "kuesioner",
                  params: {
                    id_jadwal: self.id_jadwal,
                    id_simulasi: self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
                    pin: self.jadwal_data.jadwal_detail.pin,
                  },
                });
              } else {
                this.$router.push({
                  name: "kuesioner-combo",
                  params: {
                    id_jadwal: self.id_jadwal,
                    id_simulasi: self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
                    pin: self.jadwal_data.jadwal_detail.pin,
                  },
                });
              }
              */
              this.$router.push({
                name: "kuesioner-combo",
                params: {
                  id_jadwal: self.id_jadwal,
                  id_simulasi: self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
                  pin: self.jadwal_data.jadwal_detail.pin,
                },
              });
            }
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getInfoSimulasi() {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi/" + self.jadwal_data.jadwal_detail.simulasi_id;
      var config = {
        method: "get",
        url: url,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          self.txt_materi = response_data.data.level;
          self.txt_simulasi = response_data.data.nama_simulasi;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    handleCountdownProgress(data) {
      let self = this;
      self.sisa_waktu = data.totalMinutes;
      var count_second = data.seconds;
      if (count_second % 30 == 0) {
        // interval 30 seconds.
        self.postAutoHistory();
      }
    },
    fetchPetunjuk() {
      let self = this;
      const apiUrl = process.env.VUE_APP_BACKEND_URL_VERSION + `petunjuk/${self.jadwal_data.jadwal_detail.id}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl)
        .then((response) => {
          var responseData = response.data;
          self.list_petunjuk = responseData.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    fetchSoal() {
      let self = this;
      const apiUrl = process.env.VUE_APP_BACKEND_URL_VERSION + `soal/${self.jadwal_data.jadwal_detail.id}/${self.tipe}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl)
        .then((response) => {
          var responseData = response.data;
          self.list_soal = responseData.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    toggleAccordionPetunjuk(type) {
      let self = this;
      if (type == false) {
        self.accordionPentunjuk = true;
      } else {
        self.accordionPentunjuk = false;
      }
    },
    toggleAccordionSoal(type) {
      let self = this;
      if (type == false) {
        self.accordionSoal = true;
      } else {
        self.accordionSoal = false;
      }
    },
    petunjukTrigger(data) {
      var url;
      if (data.format_file == "Video" || data.format_file == "PPT" || data.format_file == "Excel") {
        this.is_petunjuk_soal_rich_text = false;
        this.content_petunjuk_soal_rich_text = "Loading...";
        url = data.link_yt;
      } else if (data.format_file == "PDF") {
        this.is_petunjuk_soal_rich_text = false;
        this.content_petunjuk_soal_rich_text = "Loading...";
        url = this.url_backend_pdf_js + process.env.VUE_APP_BACKEND_URL + data.file;
      } else if (data.format_file == "Rich Text") {
        this.is_petunjuk_soal_rich_text = true;
        this.content_petunjuk_soal_rich_text = data.rich_text;
        url = "";
      } else {
        this.is_petunjuk_soal_rich_text = false;
        this.content_petunjuk_soal_rich_text = "Loading...";
        url = process.env.VUE_APP_BACKEND_URL + data.file;
      }
      this.showFile(url);
      this.toggleTimerSoal();
    },
    showFile(file_petunjuk) {
      this.file_embed = file_petunjuk;
    },
    toggleTimerSoal() {
      if (this.countDownSoal != 0) {
        this.timer = setInterval(() => {
          this.countDownSoal--;
          if (this.countDownSoal === 0) {
            clearInterval(this.timer);
            this.is_soal_enable = false;
            // countdown finished
          }
        }, 1000);
      }
    },
    soalTrigger(data) {
      let self = this;
      var url = "";
      self.is_soal_active = { materi_file_id: 0 };
      self.postDataSoalTrigger();
      if (data.tipe == "Video" || data.tipe == "PPT" || data.tipe == "Excel") {
        self.is_petunjuk_soal_rich_text = false;
        self.content_petunjuk_soal_rich_text = "Loading...";
        url = data.link_yt;
      } else if (data.tipe == "PDF") {
        self.is_petunjuk_soal_rich_text = false;
        self.content_petunjuk_soal_rich_text = "Loading...";
        url = self.url_backend_pdf_js + process.env.VUE_APP_BACKEND_URL + data.file;
      } else if (data.tipe == "Rich Text") {
        self.is_petunjuk_soal_rich_text = true;
        self.content_petunjuk_soal_rich_text = data.rich_text;
        url = "";
      } else {
        self.is_petunjuk_soal_rich_text = false;
        self.content_petunjuk_soal_rich_text = "Loading...";
        url = process.env.VUE_APP_BACKEND_URL + data.file;
      }
      self.showFile(url);
    },
    postDataSoalTrigger() {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/store-soal-trigger";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal: self.id_jadwal,
          id_jadwal_detail: self.id_jadwal_detail,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
          sisa_waktu: self.sisa_waktu,
          nip: self.jadwal_data.jadwal_detail.nip_asesi,
          pin: self.jadwal_data.jadwal_detail.pin,
          jawaban: self.editorData,
          soal_data: JSON.stringify(self.is_soal_active),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.editorData = response_data.data.jawaban_history;
            self.last_update_soal = response_data.data.last_update;
            console.log("berhasil simpan ke Jawaban History");
          } else {
            console.log(response_data.data.message);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    postAutoHistory() {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/store-auto-history-data";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal: self.id_jadwal,
          id_jadwal_detail: self.id_jadwal_detail,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
          sisa_waktu: self.sisa_waktu,
          nip: self.jadwal_data.jadwal_detail.nip_asesi,
          pin: self.jadwal_data.jadwal_detail.pin,
          jawaban: self.editorData,
          soal_data: JSON.stringify(self.is_soal_active),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            console.log("berhasil simpan ke Jawaban History");
          } else {
            console.log(response_data.data.message);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    postAnswer() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/store";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal: self.id_jadwal,
          id_jadwal_detail: self.id_jadwal_detail,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
          sisa_waktu: self.sisa_waktu,
          nip: self.jadwal_data.jadwal_detail.nip_asesi,
          pin: self.jadwal_data.jadwal_detail.pin,
          jawaban: self.editorData,
          soal_data: JSON.stringify(self.is_soal_active),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Berhasil simpan Jawaban.",
            });
          } else {
            console.log(response_data.data.message);
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errors,
          });
        });
    },
    checkDurasi() {
      let self = this;
      if (self.sisa_waktu == 0) {
        self.submitTestDone();
      }
    },
    submitTestDone() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/submit-test-done";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal_detail: self.id_jadwal_detail,
          sisa_waktu: self.sisa_waktu,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            Swal.close();
            // var is_combo = self.jadwal_data.jadwal_detail.jadwal_new.j_is_combo;
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Pengerjaan Selesai, Menuju ke halaman Kuesioner.",
            });
            // if (is_combo == "NO") {
            //   this.$router.push({
            //     name: "kuesioner",
            //     params: {
            //       id_jadwal: self.id_jadwal,
            //       id_simulasi: self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
            //       pin: self.jadwal_data.jadwal_detail.pin,
            //     },
            //   });
            // } else {
            this.$router.push({
              name: "kuesioner-combo",
              params: {
                id_jadwal: self.id_jadwal,
                id_simulasi: self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
                pin: self.jadwal_data.jadwal_detail.pin,
              },
            });
            // }
            // this.$router.push({
            //   name: "detail-jadwal",
            //   params: {
            //     id: this.id_jadwal,
            //   },
            // });
          } else {
            console.log(response_data.data.message);
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errors,
          });
        });
    },
    halamanDetailJadwal() {
      this.$router.push({
        name: "detail-jadwal",
        params: {
          id: this.$route.params.id_jadwal,
        },
      });
    },
    toggleFullScreenJawaban() {
      const card = this.$el.querySelector(".card-jawaban");
      card.classList.toggle("full-screen-card-jawaban");
      this.isFullScreenJawaban = !this.isFullScreenJawaban;
    },
    toggleFullScreenSoal() {
      const card = this.$el.querySelector(".card-petunjuk");
      card.classList.toggle("full-screen-card-soal");
      this.isFullScreenSoal = !this.isFullScreenSoal;
    },
    toggleFullScreen(section) {
      if (section === "soal") {
        this.isFullScreenSoal = !this.isFullScreenSoal;
      } else if (section === "jawaban") {
        this.isFullScreenJawaban = !this.isFullScreenJawaban;
      }
    },
    // Method dari masing masing fungsi di dalam file ini
  },
};
</script>
<template>
  <div class="wrapper-jawaban">
    <div class="row">
      <div class="col-6">
        <button class="btn btn-warning mt-2 btn-sm" @click="halamanDetailJadwal()"><i class="fa fa-arrow-left"></i> Kembali</button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-6">
            <div class="judul" v-if="txt_materi && txt_simulasi">
              <h4>{{ txt_materi }} - Materi {{ txt_simulasi }}</h4>
            </div>
          </div>
          <div class="col-6">
            <div class="text-end">
              <span class="h4"
                >Sisa Waktu
                <span id="timer">
                  <vue-countdown :time="waktu_mulai" v-slot="{ hours, minutes, seconds }" @progress="handleCountdownProgress" @end="checkDurasi">
                    {{ hours }} : {{ minutes }} : {{ seconds }}
                  </vue-countdown>
                </span>
              </span>
              <button class="btn btn-success ms-4 btn-md" v-on:click="submitTestDone"><i class="fa fa-check"></i> Selesai Mengerjakan</button>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-2">
            <!-- Komponen Petunjuk -->
            <b-card no-body>
              <b-card-header header-tag="header" class="p-3" role="tab">
                <b-button block v-b-toggle.collapse1 variant="info" v-on:click="toggleAccordionPetunjuk(accordionPentunjuk)">
                  Petunjuk&nbsp;
                  <i
                    class="float-right"
                    :class="{
                      'fa fa-chevron-up': accordionPentunjuk,
                      'fa fa-chevron-down': !accordionPentunjuk,
                    }"
                  ></i>
                </b-button>
              </b-card-header>
              <b-collapse id="collapse1" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <ul class="submenu" v-for="(petunjuk, key) in list_petunjuk" :key="key">
                    <li>
                      <a href="#" @click="petunjukTrigger(petunjuk)">
                        {{ petunjuk.judul_file }}
                      </a>
                    </li>
                  </ul>
                </b-card-body>
              </b-collapse>
            </b-card>

            <!-- Komponen Soal -->
            <b-card no-body class="mt-1">
              <b-card-header header-tag="header" class="p-3" role="tab">
                <b-button block v-b-toggle.collapse2 variant="info" :disabled="is_soal_enable" v-on:click="toggleAccordionSoal(accordionSoal)">
                  Soal&nbsp;
                  <i
                    class="float-right"
                    :class="{
                      'fa fa-chevron-up': accordionSoal,
                      'fa fa-chevron-down': !accordionSoal,
                    }"
                  ></i>
                </b-button>
              </b-card-header>
              <b-collapse id="collapse2" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <ul class="submenu" v-for="(soal, key) in list_soal" :key="key">
                    <li>
                      <a href="#" @click="soalTrigger(soal)">
                        {{ soal.judul }}
                      </a>
                    </li>
                  </ul>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div
            :class="{
              'col-5 order-1': !isFullScreenSoal && !isFullScreenJawaban,
              'col-10 order-1': isFullScreenSoal && !isFullScreenJawaban,
              'col-12 order-2': !isFullScreenSoal && isFullScreenJawaban,
            }"
          >
            <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center no-margin">
                <div>
                  <!-- Your existing buttons -->
                </div>
                <div>
                  <!-- New button for full-screen -->
                  <button
                    :class="{
                      'btn btn-primary': !isFullScreenSoal,
                      'btn btn-danger': isFullScreenSoal,
                    }"
                    @click="toggleFullScreen('soal')"
                  >
                    <i v-if="!isFullScreenSoal" class="fas fa-expand-alt"></i>
                    <i v-else class="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <div class="card-body mt-2">
                <div v-if="is_petunjuk_soal_rich_text == true">
                  <editor
                    api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0"
                    :init="{
                      height: 300,
                      menubar: false,
                      plugins: [],
                      toolbar: '',
                      contextmenu_never_use_native: true,
                      paste_preprocess: function (plugin, args) {
                        args.content = '';
                      },
                    }"
                    v-model="content_petunjuk_soal_rich_text"
                  />
                </div>
                <div v-else>
                  <div v-if="file_embed">
                    <PDFViewer :pdfUrl="file_embed" />
                  </div>
                  <div v-else>Loading...</div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="{
              'col-5 order-1': !isFullScreenJawaban && !isFullScreenSoal,
              'col-10 order-1': isFullScreenJawaban && !isFullScreenSoal,
              'col-12 order-2': !isFullScreenJawaban && isFullScreenSoal,
            }"
          >
            <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center no-margin">
                <div class="d-flex align-items-center"></div>
                <div>
                  <!-- New button for full-screen -->
                  <button
                    :class="{
                      'btn btn-primary': !isFullScreenJawaban,
                      'btn btn-danger': isFullScreenJawaban,
                    }"
                    @click="toggleFullScreen('jawaban')"
                  >
                    <i v-if="!isFullScreenJawaban" class="fas fa-expand-alt"></i>
                    <i v-else class="fas fa-times"></i>
                  </button>
                </div>
              </div>

              <div class="card-body" style="height: 600px">
                <DocumentEditor id="docEditor" :document-server-url="url_onlyoffice" :config="config" :events_onDocumentReady="onDocumentReady" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.no-margin {
  padding: 5 !important;
  background-color: rgb(243, 243, 243);
  border-color: aqua;
  border: 1px;
}

.card-header {
  border: 2px solid rgb(255, 255, 255);
  /* Customize the border color and other border properties */
}
.wrapper-jawaban {
  padding: 30px;
  /* Customize the border color and other border properties */
}
.full-screen-card-soal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  z-index: 1000;
  background-color: white;
  /* Additional styles for full screen */
}
.full-screen-card-jawaban {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  z-index: 1000;
  background-color: white;
  /* Additional styles for full screen */
}
</style>
