<!-- PDFViewer.vue -->
<template>
  <div @contextmenu="disableContextMenu">
    <iframe
      :src="url + '#toolbar=0'"
      width="100%"
      :height="isFullScreen ? 600 : 500 "
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    pdfUrl: String, // URL of the PDF to embed
    isFullScreen : Boolean
  },
  data() {
    return {
      url: "",
    };
  },
  watch: {
    // Watch for changes to the pdfUrl prop
    pdfUrl: {
      handler: function (newPdfUrl) {
        // Update the URL when the prop changes
        this.url =  newPdfUrl;
      },
      immediate: true, // Update immediately when the component is created
    },
  },
  methods: {
    disableContextMenu(event) {
      // Prevent the right-click context menu
      event.preventDefault();
    },
  },
};
</script>
